import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemPrefix,

  Switch,
  Radio
} from "@material-tailwind/react";

import { MdDelete } from "react-icons/md";

import { addNewRoleApi } from "api/operatorRole";
import { operatorsAccessList } from "api/filtersData";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { ToastContainer, toast } from 'react-toastify';

function NewRole({ closePopup }) {
  const [sideNavItems, setSideNavItems] = useState([]);
  const [selectedSideNavItems, setSelectedSideNavItems] = useState([]);

  const [selectedAccess, setSelectedAccess] = useState("");
  const [selectedRoleName, setSelectedRoleName] = useState("")

  const [mobileAccess, setMobileAccess] = useState(false)

  const [mobileAccessObject, setMobileAccessObject] = useState({
    isDG: false,
    isDC: false,
    isC: false,
    isTD: false,
  })

  useEffect(() => {
    getInitialData()
  }, [])

  const getInitialData = async () => {
    try {
      const response = await operatorsAccessList()
      setSideNavItems(response)
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleMobileAccessObjectChange = e => {
    const { name, value } = e.target;
    setMobileAccessObject((prevMobileAccessObject) => ({
      ...prevMobileAccessObject,
      isDG: value === "isDG" ? true : false,
      isDC: value === "isDC" ? true : false,
      isC: value === "isC" ? true : false,
      isTD: value === "isTD" ? true : false,
    }));
  }

  const handleMobileAccessChange = () => {
    try {
      setMobileAccess(!mobileAccess)
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleAddNewRole = () => {
    try {
      withReactContent(Swal)
      .fire({
        icon: 'info',
        title: "voulez-vous ajouter ce rôle ?",
        showCancelButton: true,
        cancelButtonText: 'Annuler',
        confirmButtonText: "Ajouter",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await addNewRoleApi(selectedRoleName, selectedSideNavItems, mobileAccess, mobileAccessObject)
          Swal.fire("Ajoutée", "", "success")
          closePopup('Reload')
        }
      });
    } catch (error) {}
  };

  const handleCancelOp = () => {
    try {
      closePopup("Close");
    } catch (error) {}
  };

  const handleAddNewAccess = () => {
    try {
      const sideNavItem = sideNavItems.find((el) => el._id === selectedAccess);

      const checkIfAlreadyExist = selectedSideNavItems.find(
        (el) => el.value === selectedAccess
      );

      if (checkIfAlreadyExist) {
        return  toast.error("Existe déjà !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        });
      }

      const newAccessObject = {
        label: sideNavItem.label,
        path: sideNavItem.path,
        value: sideNavItem._id,
        privilege: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
      };

      setSelectedSideNavItems((prevState) => [...prevState, newAccessObject]);
    } catch (error) {}
  };

  const handleDeleteAccess = (id) => {
    try {
      setSelectedSideNavItems((prevState) =>
        prevState.filter((item) => item.value !== id)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleAccessChange = (e) => {
    setSelectedAccess(e.target.value);
  };

  const handleRoleNameChange = (e) => {
    setSelectedRoleName(e.target.value);
  };

  const handleCheckboxChange = (index, privilegeType) => {
    setSelectedSideNavItems(prevState => prevState.map((item, idx) => idx === index ? {...item, privilege: {...item.privilege, [privilegeType]: !item.privilege[privilegeType]}} : item));
  };

  return (
    <div className="newRoleModale">
      <ToastContainer />
      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div></div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Nom de votre rôle
          </label>
          <input
            type="text"
            id=""
            placeholder="Rôle"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            onChange={handleRoleNameChange}
          />
        </div>
        <div></div>
      </div>

      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div className="md:col-span-1">
          <label htmlFor="" className="text-md font-bold text-navy-700">
            List des accéss
          </label>
          <select
            label="select Version"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            onChange={handleAccessChange}
          >
            <option value="Tout" defaultChecked>
              Selectionner une accéss
            </option>
            {
              sideNavItems.map((el) => (
                <option value={el._id}>{el.name}</option>
              ))
            }
          </select>

          <Button
            variant="gradient"
            color="#236571"
            onClick={() => handleAddNewAccess()}
            className="mt-2 w-full"
          >
            <span>Ajouter</span>
          </Button>

          <div className="mt-3">
            <small className="text-sm font-bold text-navy-700 dark:text-white">
              <p className="text-center">Accès mobile</p>
            </small>
            <div className="flex w-full items-center justify-center">
              <Switch checked={mobileAccess} onChange={handleMobileAccessChange} />
            </div>
          </div>

          {
            mobileAccess &&
            <div className="grid grid-cols-1 lg:grid-cols-2">
              <Radio
                name="mobileAccess"
                value="isDG"
                onChange={handleMobileAccessObjectChange}
                label="Directeur général"
                checked={mobileAccessObject.isDG}
              />
              <Radio
                name="mobileAccess"
                value="isDC"
                onChange={handleMobileAccessObjectChange}
                label="Directeur commercial"
                checked={mobileAccessObject.isDC}
              />
              <Radio
                name="mobileAccess"
                value="isC"
                onChange={handleMobileAccessObjectChange}
                label="Commercial"
                checked={mobileAccessObject.isC}
              />
              <Radio
                name="mobileAccess"
                value="isTD"
                onChange={handleMobileAccessObjectChange}
                label="Département technique"
                checked={mobileAccessObject.isTD}
              />
            </div>
          }

        </div>
        <div className="md:col-span-2">
          <p className="text-md text-center font-bold text-navy-700">
            List des accéss selectionner
          </p>

          <div className="accessListCard mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
            {selectedSideNavItems.map((el, index) => (
              <Card className="w-full">
                <CardBody>
                  <Typography variant="h5" color="blue-gray" className="mb-2">
                    {el.label}
                  </Typography>
                  <Typography></Typography>
                </CardBody>
                <CardFooter className="pt-0">
                  <div className="">
                    <List className="grid grid-cols-1 flex-row gap-5 sm:grid-cols-2">
                      <ListItem className="p-0">
                        <label
                          htmlFor="horizontal-list-add"
                          className="flex w-full cursor-pointer items-center px-3 py-2"
                        >
                          <ListItemPrefix className="mr-3">
                            <Checkbox
                              checked={el.privilege.create}
                              onChange={() => handleCheckboxChange(index, 'create')}
                              id="horizontal-list-add"
                              ripple={false}
                              className="hover:before:opacity-0"
                              containerProps={{
                                className: "p-0",
                              }}
                            />
                          </ListItemPrefix>
                          <Typography color="blue-gray" className="font-medium">
                            Ajouter
                          </Typography>
                        </label>
                      </ListItem>

                      <ListItem className="p-0">
                        <label
                          htmlFor="horizontal-list-read"
                          className="flex w-full cursor-pointer items-center px-3 py-2"
                        >
                          <ListItemPrefix className="mr-3">
                            <Checkbox
                              checked={el.privilege.read}
                              onChange={() => handleCheckboxChange(index, 'read')}
                              id="horizontal-list-read"
                              ripple={false}
                              className="hover:before:opacity-0"
                              containerProps={{
                                className: "p-0",
                              }}
                            />
                          </ListItemPrefix>
                          <Typography color="blue-gray" className="font-medium">
                            Voir
                          </Typography>
                        </label>
                      </ListItem>

                      <ListItem className="p-0">
                        <label
                          htmlFor="horizontal-list-update"
                          className="flex w-full cursor-pointer items-center px-3 py-2"
                        >
                          <ListItemPrefix className="mr-3">
                            <Checkbox
                              checked={el.privilege.update}
                              onChange={() => handleCheckboxChange(index, 'update')}
                              id="horizontal-list-update"
                              ripple={false}
                              className="hover:before:opacity-0"
                              containerProps={{
                                className: "p-0",
                              }}
                            />
                          </ListItemPrefix>
                          <Typography color="blue-gray" className="font-medium">
                            Modifier
                          </Typography>
                        </label>
                      </ListItem>

                      <ListItem className="p-0">
                        <label
                          htmlFor="horizontal-list-delete"
                          className="flex w-full cursor-pointer items-center py-2"
                        >
                          <ListItemPrefix className="mr-3">
                            <Checkbox
                              checked={el.privilege.delete}
                              onChange={() => handleCheckboxChange(index, 'delete')}
                              id="horizontal-list-delete"
                              ripple={false}
                              className="hover:before:opacity-0"
                              containerProps={{
                                className: "p-0",
                              }}
                            />
                          </ListItemPrefix>
                          <Typography color="blue-gray" className="font-medium">
                            Supprimer
                          </Typography>
                        </label>
                      </ListItem>
                    </List>
                    <div className="flex justify-center">
                      <MdDelete
                        size={40}
                        color="#cf503f"
                        className="cursor-pointer"
                        onClick={() => handleDeleteAccess(el.value)}
                      />
                    </div>
                  </div>
                </CardFooter>
              </Card>
            ))}
          </div>
        </div>
      </div>

      <div className="mt-5">
        <Button
          variant="gradient"
          color="#236571"
          onClick={() => handleAddNewRole()}
          className="mr-1"
        >
          <span>Ajouter ce rôle</span>
        </Button>
        <Button
          variant="text"
          color="#cf503f"
          onClick={() => handleCancelOp()}
          className="mr-1"
        >
          <span>Annuler</span>
        </Button>
      </div>
    </div>
  );
}

export default NewRole;
