import React, { useEffect, useState } from "react";
import Card from "components/card";
import { Button, Spinner } from "@material-tailwind/react";

import { IoEyeSharp } from "react-icons/io5";
import { HiPencilAlt } from "react-icons/hi";
import { FaTrash } from "react-icons/fa";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { ToastContainer, toast } from "react-toastify";

import { Dialog, DialogHeader, DialogBody, Switch } from "@material-tailwind/react";
import { getAllRoleApi } from "api/operatorRole";
import Role_details from "./popups/Role_details";
import { deleteRoleApi } from "api/operatorRole";
import { updateSignatureRoleApi } from "api/operatorRole";

function ListOfRoles() {
  const [isLoading, setIsLoading] = useState(false);

  const [showRoleModal, setShowRoleModal] = useState(false)
  const [roleModalAction, setRoleModalAction] = useState(false)

  const [rolesList, setRolesList] = useState([])
  const [selectedRoleData, setSelectedRoleData] = useState("")

  useEffect(() => {
    getInitialData()
  }, [])

  const getInitialData = async () => {
    try {
        setIsLoading(true)
        
        const response = await getAllRoleApi()
        setRolesList(response)
    }
    catch (err) {
        console.log(err)
    }
    finally {
        setIsLoading(false)
    }
  }

  const handleDeleteRole = async (roleID) => {
    try {
        withReactContent(Swal)
          .fire({
            icon: "warning",
            title: "Supprimer ?",
            confirmButtonText: "Supprimer",
          })
          .then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              await deleteRoleApi(roleID)
              getInitialData()
              Swal.fire("Supprimée", "", "success");
            }
          });
      } catch (err) {
        console.log(err);
      }
  }

  const handleRoleModal = (action, data) => {
    try {
        setShowRoleModal(!showRoleModal);
        setRoleModalAction(action);
        setSelectedRoleData(data)
    } catch (err) {
      console.log(err);
    }
  };

  const handleCloseRoleModal = () => {
    try {
        setShowRoleModal(!showRoleModal);
        getInitialData()
    }
    catch (err) {
        console.log(err);
    }
  }

  const handleChangeSignatureStatus = async (signatureStatus, Role, ID) => {
    try {
      const signatureObject = {
        DG: false,
        DC: false,
        MG: false,
        F: false,
      }

      signatureObject[Role] = !signatureStatus

      await updateSignatureRoleApi(signatureObject, ID)
      getInitialData()

      return toast.success("Modifiée", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    catch (err) {
      console.log(err);
    }
  }

  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <ToastContainer />

      <Dialog size="xxl" open={showRoleModal} handler={handleRoleModal}>
        <DialogHeader>
          <p className="w-full text-center"></p>
        </DialogHeader>
        <DialogBody>
          <Role_details closePopup={handleCloseRoleModal} roleModalAction={roleModalAction} selectedRoleData={selectedRoleData} />
        </DialogBody>
      </Dialog>

      <header className="relative flex flex-col items-center justify-between md:flex-row">
        <div className="mb-5 text-xl font-bold text-navy-700 dark:text-white md:mb-0"></div>
        <Button
          className="linear mb-5 rounded-[10px] bg-gray-500 px-4 py-2 text-xs font-bold text-white transition duration-200 hover:bg-gray-400 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:mb-0"
          onClick={() => handleRoleModal("new")}
        >
          Nouveau rôle
        </Button>
      </header>

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2"></div>

      <div className="mt-8 overflow-x-scroll">
        {isLoading ? (
          <div className="flex w-full items-center justify-center">
            <Spinner className="h-12 w-12" />
          </div>
        ) : (
          <div
          className="mt-8 overflow-x-scroll"
          style={{ height: "50vh", overflowY: "scroll" }}
        >
          <table className="w-full">
            <thead>
              <tr>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">Nom</p>
                </th>

                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">Signature Directeur générale</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">Signature Directeur commercial</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">Signature magasinier</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">Signature facturation</p>
                </th>

                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">Actions</p>
                </th>
              </tr>
            </thead>

            <tbody>
                {
                    rolesList.map(el => (
                    <tr>
                        <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {el.name}
                          </p>
                        </td>

                        <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                          <Switch
                            checked={el?.signature?.DG}
                            onChange={() =>
                              handleChangeSignatureStatus(el?.signature?.DG, "DG", el._id)
                            }
                          />
                        </td>
                        <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                        <Switch
                            checked={el?.signature?.DC}
                            onChange={() =>
                              handleChangeSignatureStatus(el?.signature?.DC, "DC", el._id)
                            }
                          />
                        </td>
                        <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                          <Switch
                            checked={el?.signature?.MG}
                            onChange={() =>
                              handleChangeSignatureStatus(el?.signature?.MG, "MG", el._id)
                            }
                          />
                        </td>
                        <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                          <Switch
                            checked={el?.signature?.F}
                            onChange={() =>
                              handleChangeSignatureStatus(el?.signature?.F, "F", el._id)
                            }
                          />
                        </td>

                        <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            <div className="flex">
                              <IoEyeSharp
                                size={20}
                                color="gray"
                                className="cursor-pointer"
                                onClick={() => handleRoleModal("see", el)}
                              />
                              <HiPencilAlt
                                size={20}
                                color="#236571"
                                className="ml-4 mr-4 cursor-pointer"
                                onClick={() => handleRoleModal("edite", el)}
                              />
                              <FaTrash
                                size={20}
                                color="#cf503f"
                                className="cursor-pointer"
                                onClick={() => handleDeleteRole(el._id)}
                              />
                            </div>
                          </p>
                        </td>
                      </tr>
                    ))
                }
            </tbody>
          </table>
        </div>

        )}
      </div>
    </Card>
  );
}

export default ListOfRoles;
