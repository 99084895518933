import React, { useEffect, useState } from "react";
import Card from "components/card";
import { Button, Spinner, IconButton } from "@material-tailwind/react";

import { IoEyeSharp } from "react-icons/io5";
import { HiPencilAlt } from "react-icons/hi";
import { FaTrash } from "react-icons/fa";
import { MdPictureAsPdf } from "react-icons/md";

import {
  IoMdArrowDropleftCircle,
  IoMdArrowDroprightCircle,
} from "react-icons/io";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { Dialog, DialogHeader, DialogBody } from "@material-tailwind/react";

import OperationForm from "./Operation_form";
import OperationProducts from "./Operation_products";
import OperationReports from "./Operation_reports";
import { getAllOpReportsListApi } from "api/operations";
import { getAllTcpsApi } from "api/filtersData";

import { ToastContainer, toast } from "react-toastify";
import { updateReportDataApi } from "api/gestionStock";

function ListOfOperations({ accessList }) {
  const [showOperationForm, setOperationForm] = useState(false);
  const [showOperationReports, setOperationReports] = useState(false);
  const [showOperationProducts, setOperationProducts] = useState(false);

  const [listOfOperations, setListOfOperations] = useState([]);
  const [listOfAllTcp, setListOfTcp] = useState([]);

  const [inputFilterValue, setInputFilterValue] = useState({
    TCP: "",
    OP: "",
    currentYear: new Date().getUTCFullYear(),

    RBC: "",
    RBL: "",
    RF: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [selectedOp, setSelectedOp] = useState({});

  // paginations
  const [active, setActive] = useState(1);
  const [currentPagination, setCurrentPagination] = useState(1);

  const [numberOfPages, setNumberOfPages] = useState(0);
  const [numberOfPaginations, setNumberOfPaginations] = useState(0);
  const [numberOfReports, setNumberOfReports] = useState(0);

  useEffect(() => {
    getInitialData(
      inputFilterValue.OP === "Avoir" ? "Avoir" : "Vente",
      inputFilterValue.TCP,
      inputFilterValue.OP,
      inputFilterValue.currentYear,
      inputFilterValue.RBC,
      inputFilterValue.RBL,
      inputFilterValue.RF,
      active
    );
    getListOfAllTcp();
  }, []);

  const handleFiltersInputChange = async (e) => {
    try {
      setIsLoading(true);

      const { name, value } = e.target;
      setInputFilterValue({ ...inputFilterValue, [name]: value });

      if(name === "OP") {
        if(value === "Avoir") {
          await getInitialData(
            "Avoir",
            name === "TCP" ? value : inputFilterValue.TCP,
            "",
            name === "currentYear" ? value : inputFilterValue.currentYear,
            name === "RBC" ? value : inputFilterValue.RBC,
            name === "RBL" ? value : inputFilterValue.RBL,
            name === "RF" ? value : inputFilterValue.RF,
            active
          );
        }
        else {
          await getInitialData(
            "Vente",
            name === "TCP" ? value : inputFilterValue.TCP,
            name === "OP" ? value : inputFilterValue.OP,
            name === "currentYear" ? value : inputFilterValue.currentYear,
            name === "RBC" ? value : inputFilterValue.RBC,
            name === "RBL" ? value : inputFilterValue.RBL,
            name === "RF" ? value : inputFilterValue.RF,
            active
          );
        }
      }
      else {
        await getInitialData(
          inputFilterValue.OP === "Avoir" ? "Avoir" : "Vente",
          name === "TCP" ? value : inputFilterValue.TCP,
          name === "OP" ? value : inputFilterValue.OP,
          name === "currentYear" ? value : inputFilterValue.currentYear,
          name === "RBC" ? value : inputFilterValue.RBC,
          name === "RBL" ? value : inputFilterValue.RBL,
          name === "RF" ? value : inputFilterValue.RF,
          active
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCurrentPagination(1)
      setActive(1)

      setIsLoading(false);
    }
  };

  const getListOfAllTcp = async () => {
    try {
      const response = await getAllTcpsApi();
      setListOfTcp(response);
    } catch (err) {
      console.log(err);
    }
  };

  const getInitialData = async (
    MP,
    TCP,
    OP,
    currentYear,
    RBC,
    RBL,
    RF,
    Page
  ) => {
    try {
      setIsLoading(true);
      const response = await getAllOpReportsListApi(
        MP,
        TCP,
        OP,
        currentYear,
        RBC,
        RBL,
        RF,
        Page
      );

      setListOfOperations(response.data);
      setNumberOfPages(response.pagination.numberOfPages);
      setNumberOfPaginations(response.pagination.numberOfPaginations);
      setNumberOfReports(response.pagination.numberOfReports);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteReport = (ID) => {
    try {
      withReactContent(Swal)
        .fire({
          icon: "warning",
          title: "Supprimer ?",
          confirmButtonText: "Supprimer",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // isArchieved
            const updatedObject = {
              isArchieved: true,
            };
            await updateReportDataApi(ID, updatedObject);

            getInitialData(
              inputFilterValue.OP === "Avoir" ? "Avoir" : "Vente",
              inputFilterValue.TCP,
              inputFilterValue.OP,
              inputFilterValue.currentYear,
              inputFilterValue.RBC,
              inputFilterValue.RBL,
              inputFilterValue.RF,
              active
            );

            Swal.fire("Supprimée", "", "success");
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleReportModal = (data) => {
    try {
      setOperationReports(!showOperationReports);
      setSelectedOp(data);
    } catch (err) {
      console.log(err);
    }
  };
  const handleReportFormModal = (data) => {
    try {
      setOperationForm(!showOperationForm);
      setSelectedOp(data);
    } catch (err) {
      console.log(err);
    }
  };
  const handleReportProductsModal = (data) => {
    try {
      setOperationProducts(!showOperationProducts);
      setSelectedOp(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowToast = (type, message) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      getInitialData(
        inputFilterValue.OP === "Avoir" ? "Avoir" : "Vente",
        inputFilterValue.TCP,
        inputFilterValue.OP,
        inputFilterValue.currentYear,
        inputFilterValue.RBC,
        inputFilterValue.RBL,
        inputFilterValue.RF,
        active
      );
    }
    if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  // pagination
  const getItemProps = (index) => ({
    variant: active === index ? "filled" : "text",
    color: "gray",
    onClick: () => {
      getInitialData(
        inputFilterValue.OP === "Avoir" ? "Avoir" : "Vente",
        inputFilterValue.TCP,
        inputFilterValue.OP,
        inputFilterValue.currentYear,
        inputFilterValue.RBC,
        inputFilterValue.RBL,
        inputFilterValue.RF,
        index
      );
      setActive(index);
    },
  });

  const next = () => {
    if (currentPagination === numberOfPaginations) return;
    setCurrentPagination(currentPagination + 1);
  };

  const prev = () => {
    if (currentPagination === 1) return;
    setCurrentPagination(currentPagination - 1);
  };
  // pagination

  return (
    <>
      <ToastContainer />

      <Card extra={"w-full pb-10 p-4 h-full"}>
        <Dialog
          size="xl"
          open={showOperationForm}
          handler={handleReportFormModal}
        >
          <DialogHeader>
            <p className="w-full text-center">Modifier un rapport</p>
          </DialogHeader>
          <DialogBody>
            <OperationForm
              closePopup={handleReportFormModal}
              selectedOp={selectedOp}
              showToast={handleShowToast}
            />
          </DialogBody>
        </Dialog>

        <Dialog
          size="xl"
          open={showOperationReports}
          handler={handleReportModal}
        >
          <DialogHeader>
            <p className="w-full text-center">Aperçu pdf details</p>
          </DialogHeader>
          <DialogBody>
            <OperationReports
              closePopup={handleReportModal}
              selectedOp={selectedOp}
            />
          </DialogBody>
        </Dialog>

        <Dialog
          size="xl"
          open={showOperationProducts}
          handler={handleReportProductsModal}
        >
          <DialogHeader>
            <p className="w-full text-center">Aperçu</p>
          </DialogHeader>
          <DialogBody>
            <OperationProducts
              closePopup={handleReportProductsModal}
              selectedOp={selectedOp}
            />
          </DialogBody>
        </Dialog>

        <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-3">
          <div>
            <small className="text-sm font-medium text-gray-600 dark:text-white">
              Filtrer par TCP :
            </small>
            <div>
              <select
                label="select Version"
                className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
                defaultValue=""
                name="TCP"
                value={inputFilterValue.TCP}
                onChange={handleFiltersInputChange}
              >
                <option value="">Tout</option>
                {listOfAllTcp.map((el) => (
                  <option value={el._id}>{el.Name}</option>
                ))}
              </select>
            </div>
          </div>
          <div>
            <small className="text-sm font-medium text-gray-600 dark:text-white">
              Filtrer par type de rapport :
            </small>
            <div>
              <select
                label="select Version"
                className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
                defaultValue=""
                name="OP"
                value={inputFilterValue.OP}
                onChange={handleFiltersInputChange}
              >
                <option value="">Tout</option>
                <option value="BC">BC</option>
                <option value="BL">BL</option>
                <option value="F">F</option>
                <option value="Avoir">Avoir</option>
              </select>
            </div>
          </div>
          <div>
            <small className="text-sm font-medium text-gray-600 dark:text-white">
              Année :
            </small>
            <div>
              <input
                type="number"
                id=""
                placeholder="Tout"
                min="1900"
                max={new Date().getUTCFullYear()}
                step="1"
                className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
                name="currentYear"
                value={inputFilterValue.currentYear}
                onChange={handleFiltersInputChange}
              />
            </div>
          </div>
        </div>

        <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-3">
          <div>
            <small className="text-sm font-medium text-gray-600 dark:text-white">
              Bon de commande :
            </small>
            <div>
              <input
                type="text"
                placeholder="Tout"
                className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
                name="RBC"
                value={inputFilterValue.RBC}
                onChange={handleFiltersInputChange}
              />
            </div>
          </div>
          <div>
            <small className="text-sm font-medium text-gray-600 dark:text-white">
              Bon de livraison :
            </small>
            <div>
              <input
                type="text"
                placeholder="Tout"
                className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
                name="RBL"
                value={inputFilterValue.RBL}
                onChange={handleFiltersInputChange}
              />
            </div>
          </div>
          <div>
            <small className="text-sm font-medium text-gray-600 dark:text-white">
              Facture :
            </small>
            <div>
              <input
                type="text"
                placeholder="Tout"
                className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
                name="RF"
                value={inputFilterValue.RF}
                onChange={handleFiltersInputChange}
              />
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className="flex w-full items-center justify-center">
            <Spinner className="h-12 w-12" />
          </div>
        ) : (
          <div>
            <div
              className="mt-8 overflow-x-scroll"
              style={{ height: "40vh", overflowY: "scroll" }}
            >
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                      <p className="text-xs tracking-wide text-gray-600">
                        Client
                      </p>
                    </th>
                    <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                      <p className="text-xs tracking-wide text-gray-600">
                        Produit
                      </p>
                    </th>
                    <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                      <p className="text-xs tracking-wide text-gray-600">
                        Quantité
                      </p>
                    </th>
                    <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                      <p className="text-xs tracking-wide text-gray-600">TCP</p>
                    </th>
                    <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                      <p className="text-xs tracking-wide text-gray-600">BC</p>
                    </th>
                    <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                      <p className="text-xs tracking-wide text-gray-600">BL</p>
                    </th>
                    <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                      <p className="text-xs tracking-wide text-gray-600">F</p>
                    </th>
                    <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                      <p className="text-xs tracking-wide text-gray-600">
                        Actions
                      </p>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {listOfOperations.map((el) => (
                    <tr>
                      <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {el.InfoGenerale.NomSociete}
                        </p>
                      </td>
                      <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {accessList.Read && (
                            <IoEyeSharp
                              size={20}
                              color="gray"
                              className="cursor-pointer"
                              onClick={() =>
                                handleReportProductsModal(el.Rapport)
                              }
                            />
                          )}
                        </p>
                      </td>
                      <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {Object.keys(el.Rapport)
                            .reduce((total, category) => {
                              const categoryTotal = el.Rapport[
                                category
                              ].produits.reduce(
                                (categoryTotal, produit) =>
                                  categoryTotal +
                                  parseFloat(produit.quantiteByUnity),
                                0
                              );
                              return total + categoryTotal;
                            }, 0)
                            .toFixed(2)}
                        </p>
                      </td>
                      <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {el.for.Name}
                        </p>
                      </td>
                      <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {el.RBC}
                        </p>
                      </td>
                      <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {el.RBL}
                        </p>
                      </td>
                      <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {el.RF}
                        </p>
                      </td>
                      <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          <div className="flex">
                            {accessList.Read && (
                              <MdPictureAsPdf
                                size={20}
                                color="gray"
                                className="cursor-pointer"
                                onClick={() => handleReportModal(el)}
                              />
                            )}

                            {accessList.Update && (
                              <HiPencilAlt
                                size={20}
                                color="#236571"
                                className="ml-4 mr-4 cursor-pointer"
                                onClick={() => handleReportFormModal(el)}
                              />
                            )}

                            {accessList.Delete && (
                              <FaTrash
                                size={20}
                                color="#cf503f"
                                className="cursor-pointer"
                                onClick={() => handleDeleteReport(el._id)}
                              />
                            )}
                          </div>
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="mt-4 flex items-center gap-4">
              <Button
                variant="text"
                className="flex items-center gap-2"
                onClick={prev}
                disabled={currentPagination === 1}
              >
                <IoMdArrowDropleftCircle size={30} />
              </Button>
              <div className="flex items-center gap-2">
                {currentPagination !== numberOfPaginations
                  ? // paginations row
                    Array.from({
                      length: numberOfPages > 5 ? 5 : numberOfPages,
                    }).map((el, index) => {
                      const pageNumber =
                        index + 1 + (currentPagination - 1) * 5;
                      return (
                        <IconButton {...getItemProps(pageNumber)}>
                          {pageNumber}
                        </IconButton>
                      );
                    })
                  : // last pagination row
                    Array.from({ length: numberOfPages % 5 }).map(
                      (el, index) => {
                        const pageNumber =
                          index + 1 + (currentPagination - 1) * 5;
                        return (
                          <IconButton {...getItemProps(pageNumber)}>
                            {pageNumber}
                          </IconButton>
                        );
                      }
                    )}
              </div>
              <Button
                variant="text"
                className="flex items-center gap-2"
                onClick={next}
                disabled={
                  currentPagination === numberOfPaginations ||
                  numberOfPaginations === 0
                }
              >
                <IoMdArrowDroprightCircle size={30} />
              </Button>
            </div>
          </div>
        )}
      </Card>
    </>
  );
}

export default ListOfOperations;
