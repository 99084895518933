import React, { useEffect, useState } from 'react'
import Card from "components/card";

import Morocco from './ReportType/Morocco_invoce';
import Byrot from './ReportType/Byrot_invoce';
import Recovery from './ReportType/Recovery_invoce';

import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
  } from "@material-tailwind/react";

function Invoces({ accessList }) {
  const [selectedTab, setSelectedTab] = useState('')

  const [TabsData, setTabsData] = useState([])

  const [filterInputs, setFilterInputs] = useState({
    MP: "Vente",
    clientName: "",
    etatMagasin: ""
  })

  useEffect(() => {
    setTabsData([
      {
        label: "Validation de recouvrement",
        value: "Recovery",
        desc: <Recovery selected={selectedTab} filterInputs={filterInputs} accessList={accessList} />,
      }
    ])
  }, [selectedTab, filterInputs, accessList])
  
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName)
  }

  const handleInputChange = e => {
    const {name, value} = e.target
    setFilterInputs({...filterInputs, [name]: value})
  }

  return (
    <div>
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <header className="relative flex items-center justify-between">
        <div></div>
      </header>

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
          <div>
            <small className="text-sm font-medium text-gray-600 dark:text-white">
              Filtrer par status
            </small>
            <div>
              <select
                label="select Version"
                className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
                defaultValue="Tout"

                name="etatMagasin"
                value={filterInputs.etatMagasin}
                onChange={handleInputChange}
              >
                <option value="">
                  Tout
                </option>
                <option value="V">Validée</option>
                <option value="A">Annulée</option>
                <option value="N">En cours</option>
              </select>
            </div>
          </div>

          <div>
            <small className="text-sm font-medium text-gray-600 dark:text-white">
              Filtrer par client
            </small>
            <div>
              <input
                type="text"
                id=""
                placeholder=""
                className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

                name="clientName"
                value={filterInputs?.clientName}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
      <Tabs value="Recovery">
      <TabsHeader className='flex flex-col md:flex-row'>
        {TabsData.map(({ label, value }) => (
          <Tab key={value} value={value} className='mb-5 md:mb-0'>
            <p className='font-semibold text-gray-700 dark:text-indigo-500'>{label}</p>
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody>
        {TabsData.map(({ value, desc }) => (
          <TabPanel key={value} value={value}>
            {desc}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
      </div>
    </Card>
    </div>
  )
}

export default Invoces
