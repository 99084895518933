import Card from "components/card";
import React, { useEffect, useState } from "react";

import { Combobox } from "@headlessui/react";
import { productTableApi, productTableXcelApi } from "api/dashboard";
import { getAllTcpsApi, getAllClientsApi } from "api/filtersData";
import { Spinner } from "@material-tailwind/react";
import API_URL from "config";

const ProductsTable = () => {
  const [comboBoxData, setComboBoxData] = useState([]);
  const [selectedComboBoxData, setSelectedComboBoxData] = useState("Tout");
  const [tcpData, setTcpData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [query, setQuery] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingXcel, setIsLoadingXcel] = useState(false);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedTcp, setSelectedTcp] = useState("all");

  const filteredComboBoxData =
    query === ""
      ? comboBoxData
      : comboBoxData.filter((value) =>
          value.Name.toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const getInitialData = () => {
    try {
      setIsLoading(true);

      if (fromDate && toDate) {
        productTableApi(
          selectedTcp,
          selectedComboBoxData,
          fromDate,
          toDate
        )
        .then((productTableRes) => {
          setTableData(productTableRes);
        })
        .catch(err => {

        })
        .finally(() => {
          setIsLoading(false)
        })
        
      } else {
        productTableApi(
          selectedTcp,
          selectedComboBoxData,
          null,
          null
        )
        .then((productTableRes) => {
          setTableData(productTableRes);
        })
        .catch(err => {

        })
        .finally(() => {
          setIsLoading(false)
        })
      }
      
      getAllTcpsApi()
        .then(allTcps => {
          setTcpData(allTcps);
        })
        .catch(err => {
          
        })

      getAllClientsApi()
        .then(allClients => {
          setComboBoxData([
            {
              _id: null,
              Name: "Tout",
            },
            ...allClients,
          ]);
        })
        .catch(err => {

        })
    } catch (err) {
      console.log(err);
    }
  };

  const handleTcpSelectChange = (event) => {
    setSelectedTcp(event.target.value); // Update selected value in state
  };

  const handleFilterData = () => {
    try {
      getInitialData();
    }
    catch (err) {

    }
  }

  const handleDownloadXcel = async () => {
    try {
      setIsLoadingXcel(true);
      if (fromDate && toDate) {
        const productXcelTableRes = await productTableXcelApi(
          selectedTcp,
          selectedComboBoxData,
          fromDate,
          toDate
        );

        window.open(`${API_URL}/${productXcelTableRes}`, "_blank");
      } else {
        const productXcelTableRes = await productTableXcelApi(
          selectedTcp,
          selectedComboBoxData,
          null,
          null
        );

        window.open(`${API_URL}/${productXcelTableRes}`, "_blank");
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoadingXcel(false);
    }
  };

  useEffect(() => {
    getInitialData();
  }, []); // selectedTcp, selectedComboBoxData, fromDate, toDate

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner className="h-12 w-12" />
        </div>
      ) : (
        <Card extra={"w-full pb-10 p-4 h-full"}>
          <header className="relative flex items-center justify-between">
            <div className="text-xl font-bold text-navy-700 dark:text-white">
              Products table
            </div>
            {isLoadingXcel ? (
              <div className="flex items-center justify-center">
                <Spinner className="h-12 w-12" />
              </div>
            ) : (
              <button
                href=""
                className="linear rounded-[10px] bg-green-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-green-400 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                onClick={handleDownloadXcel}
              >
                Télécharger excel
              </button>
            )}
          </header>

          <div className="mt-5 grid grid-cols-3 gap-5">
            <div>
              <small className="text-sm font-medium text-gray-600 dark:text-white">
                Filtrer par date (Depuis - à)
              </small>
              <div>
                <input
                  type="date"
                  id=""
                  placeholder=""
                  className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
                  value={fromDate}
                  onChange={(event) => setFromDate(event.target.value)}
                />
                <input
                  type="date"
                  id=""
                  placeholder=""
                  className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
                  value={toDate}
                  onChange={(event) => setToDate(event.target.value)}
                />
              </div>
            </div>
            <div>
              <small className="text-sm font-medium text-gray-600 dark:text-white">
                Filtrer par TCP
              </small>
              <div>
                <select
                  label="select Version"
                  className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
                  defaultValue="Tout"
                  onChange={handleTcpSelectChange}
                  value={selectedTcp}
                >
                  <option value="all">Tout</option>
                  {tcpData.map((el) => (
                    <option value={el.Name}>{el.Name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <small className="text-sm font-medium text-gray-600 dark:text-white">
                Filtrer par client
              </small>
              <div>
                <Combobox
                  value={selectedComboBoxData}
                  onChange={setSelectedComboBoxData}
                >
                  <Combobox.Input
                    onChange={(event) => setQuery(event.target.value)}
                    // displayValue={(value) => value.name}
                    className="relative mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none"
                  />
                  <Combobox.Options className="absolute right-0 h-32 w-1/3 overflow-y-scroll bg-white">
                    {filteredComboBoxData.map((value) => (
                      <Combobox.Option
                        key={value._id}
                        value={value.Name}
                        className="cursor-pointer rounded-xl p-2 hover:bg-gray-100"
                      >
                        {value.Name}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                </Combobox>
              </div>
            </div>

            <button
                href=""
                className="linear rounded-[10px] bg-green-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-green-400 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                onClick={handleFilterData}
              >
                Filtrer
              </button>
          </div>

          <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
            <table className="Products_table w-full">
              <thead>
                <tr>
                  <th className="TH1 border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      CATEGORY
                    </p>
                  </th>
                  <th className="TH2 border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      PRODUCT
                    </p>
                  </th>
                  <th className="TH3 border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">QTY</p>
                  </th>
                  <th className="TH4 border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      MONTANT
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData?.data
                  ? Object.entries(tableData?.data).map(([key, value]) => {
                      let subQty = 0;
                      let subTotal = 0;
                      Object.entries(value).map(([index, product]) => {
                        subQty += parseFloat(product[product.length - 1].sumOfQuantity)
                        subTotal += parseFloat(product[product.length - 1].sumOfAmount)
                        // product.map((el) => {
                        //   subTotal += parseFloat(el.Amount);
                        //   subQty += parseFloat(el.Quantity);
                        // });
                      });
                      return (
                        <>
                          {Object.entries(value).map(([key2, value2]) => {
                            let oneProductQty = 0;
                            let oneProductAmount = 0;

                            oneProductQty = value2[value2.length - 1].sumOfQuantity
                            oneProductAmount = value2[value2.length - 1].sumOfAmount

                            return (
                              <tr key={key2}>
                                <td className="TD1 pt-[14px] pb-[18px] sm:text-[14px]">
                                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    {key}
                                  </p>
                                </td>
                                <td className="TD2 pt-[14px] pb-[18px] sm:text-[14px]">
                                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    {key2}
                                  </p>
                                </td>
                                <td className="TD3 pt-[14px] pb-[18px] sm:text-[14px]">
                                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    {oneProductQty.toFixed(2)}
                                  </p>
                                </td>
                                <td className="TD4 pt-[14px] pb-[18px] sm:text-[14px]">
                                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    {oneProductAmount.toFixed(2)}
                                  </p>
                                </td>
                              </tr>
                            );
                          })}

                          <tr className="TR_color bg-gray-100 dark:bg-brand-400">
                            <td className="TD4 sm:text-[14px]">
                              <p className="text-sm font-bold text-navy-700 dark:text-white"></p>
                            </td>
                            <td className="TD4 sm:text-[14px]">
                              <p className="text-sm font-bold text-navy-700 dark:text-white"></p>
                            </td>
                            <td className="TD4 sm:text-[14px]">
                              <p className="text-sm text-[16px] font-bold text-navy-700 dark:text-white">
                                {subQty.toFixed(2)}
                              </p>
                            </td>
                            <td className="TD4 sm:text-[14px]">
                              <p className="text-sm text-[16px] font-bold text-navy-700 dark:text-white">
                                {subTotal.toFixed(2)}
                              </p>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  : ""}
                <tr className="mt-5 bg-gray-200 dark:bg-brand-400">
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white"></p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white"></p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-[22px] text-sm font-bold text-navy-700 dark:text-white">
                      {tableData?.sumOfQuantity?.toFixed(2)}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-[22px] text-sm font-bold text-navy-700 dark:text-white">
                      {tableData?.sumOfAmount?.toFixed(2)}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Card>
      )}
    </>
  );
};

export default ProductsTable;
