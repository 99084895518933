import React, { useEffect, useState } from "react";
import { Button } from "@material-tailwind/react";
import { getAllRoleNameApi } from "api/operatorRole";
import { addNewOperatorApi } from "api/operator";

import { ToastContainer, toast } from "react-toastify";
import { getOneOperatorApi } from "api/operator";

import API_URL from "config";
import { updateOperatorApi } from "api/operator";
import { updateOperatorPasswordApi } from "api/operator";
import { getAllDepotsApi } from "api/depot";

function EditeOp({ closePopup, opID, initialData }) {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);

  const [file2, setFile2] = useState(null);
  const [preview2, setPreview2] = useState(null);

  const [operatorRole, setOPeratorRole] = useState([]);

  const [operatorForm, setOperatorForm] = useState();

  const [operatorPasswordForm, setOperatorPasswordForm] = useState({
    "newPassword": "",
    "confirmPassword": ""
  })

  const [listOfZone, setListOfZone] = useState([]);

  useEffect(() => {
    allOperatorRoles();
    getOneOperatorDetails();
  }, [opID]);

  const urlToFile = async (url) => {
    try {
      // Fetch the file from the URL
      const response = await fetch(url);
      const blob = await response.blob();

      // Extract file name from URL
      const fileName = url.substring(url.lastIndexOf("/") + 1);

      // Create a new File object
      const file = new File([blob], fileName, {
        type: blob.type,
        lastModified: new Date().getTime(),
      });

      return file;
    } catch (error) {
      console.error("Error converting URL to File:", error);
      return null;
    }
  };

  const allOperatorRoles = async () => {
    try {
      const response = await getAllRoleNameApi();
      setOPeratorRole(response);

      const response2 = await getAllDepotsApi()
      const listOfDepos = []
      response2.map(el => {
        listOfDepos.push({label: el.Name, value: el.Origin})
      })
      setListOfZone(listOfDepos)
    } catch (err) {}
  };

  const getOneOperatorDetails = async () => {
    try {
      const response = await getOneOperatorApi(opID);

      const operatorDetails = {
        userFromRole: response?.userFromRole?._id || "",
        Name: response?.Name || "",
        Phone: response?.Phone || "",
        Email: response?.Email || "",
        Cin: response?.Cin || "",
        NameCode: response?.NameCode || "",
        UserVehicul: response?.UserVehicul || "",
        Zone: response?.Zone || "",
      };

      const imageProfile = response?.imageProfile?.path
        ? `${API_URL}/${response?.imageProfile?.path}`
        : null;
      const signature = response?.Signature?.path
        ? `${API_URL}/${response?.Signature?.path}`
        : null;

      if (imageProfile) {
        urlToFile(imageProfile).then((file) => {
          // console.log('Converted File:', file);
          setFile(file);

          // Preview the file
          const reader = new FileReader();
          reader.onloadend = () => {
            setPreview(reader.result);
          };
          reader.readAsDataURL(file);
        });
      }
      if (signature) {
        urlToFile(signature).then((file) => {
          // console.log('Converted File:', file);
          setFile2(file);

          // Preview the file
          const reader = new FileReader();
          reader.onloadend = () => {
            setPreview2(reader.result);
          };
          reader.readAsDataURL(file);
        });
      }

      setOperatorForm(operatorDetails);
    } catch (err) {}
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    // Preview the file
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(selectedFile);

    console.log(selectedFile);
  };

  const handleFileChange2 = (event) => {
    const selectedFile = event.target.files[0];
    setFile2(selectedFile);

    // Preview the file
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview2(reader.result);
    };
    reader.readAsDataURL(selectedFile);
  };

  const handleCancelOp = () => {
    try {
      closePopup("Close");
    } catch (err) {}
  };
  const handleUpdateOp = async () => {
    try {
      // await addNewOperatorApi(operatorForm, file, file2);
      await updateOperatorApi(operatorForm, file, file2, opID)
      initialData("Reload");
    } catch (err) {
      toast.error(
        err?.response?.data?.error ||
          "Remplir toutes les données du formulaire",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  const handleOpImageClick = () => {
    document.getElementById("op_image").click();
  };

  const handleSignatureImageClick = () => {
    document.getElementById("signature_image").click();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setOperatorForm((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handlePasswordInputChange = (event) => {
    const { name, value } = event.target
    setOperatorPasswordForm(prev => {
      return { ...prev, [name]: value }
    })
  }

  const handleUpdatePassword = async () => {
    try {
      await updateOperatorPasswordApi(opID, operatorPasswordForm.newPassword, operatorPasswordForm.confirmPassword)
      initialData("Reload");
    }
    catch (err) {
      toast.error(
        err?.response?.data?.error,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  }

  return (
    <form className="h-[80vh] overflow-scroll md:h-auto md:overflow-auto">
      <ToastContainer />
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
        <div>
          <label htmlFor="op_image" className="text-md font-bold text-navy-700">
            Image de profile
          </label>
          <div>
            <input
              type="file"
              id="op_image"
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept="image/*"
            />
            {!preview && (
              <label htmlFor="op_image" style={{ cursor: "pointer" }}>
                Clicker ici pour sélectionner votre image de profile
              </label>
            )}
            {preview && (
              <div>
                {/* Display the file preview */}
                {file.type.startsWith("image/") ? (
                  <img
                    src={preview}
                    alt="File Preview"
                    onClick={handleOpImageClick}
                    style={{
                      maxWidth: "120px",
                      height: "100px",
                      objectFit: "cover",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <p>File type not supported for preview</p>
                )}
              </div>
            )}
          </div>
        </div>
        <div>
          <label
            htmlFor="signature_image"
            className="text-md font-bold text-navy-700"
          >
            Signature
          </label>
          <div>
            <input
              type="file"
              id="signature_image"
              style={{ display: "none" }}
              onChange={handleFileChange2}
              accept="image/*"
            />
              {!preview2 && (
                <label htmlFor="signature_image" style={{ cursor: "pointer" }}>
                  Clicker ici pour sélectionner votre signature
                </label>
              )}
            {preview2 && (
              <div>
                {/* Display the file preview2 */}
                {file2.type.startsWith("image/") ? (
                  <img
                    src={preview2}
                    alt="File preview2"
                    onClick={handleSignatureImageClick}
                    style={{
                      maxWidth: "100%",
                      height: "100px",
                      objectFit: "cover",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <p>File type not supported for preview</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-5 md:grid-cols-1">
        <div>
          <label htmlFor="op_role" className="text-md font-bold text-navy-700">
            Rôle
          </label>
          <select
            label="select Version"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            value={operatorForm?.userFromRole}
            id="op_role"
            name="userFromRole"
            onChange={handleInputChange}
          >
            <option disabled value="">
              Selectionner votre role
            </option>
            {operatorRole.map((el) => (
              <option value={el?._id}>{el?.name}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Nom compléte
          </label>
          <input
            type="text"
            id=""
            placeholder="Nom complet"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            name="Name"
            onChange={handleInputChange}
            value={operatorForm?.Name}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Téléphone
          </label>
          <input
            type="text"
            id=""
            placeholder="Téléphone"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            name="Phone"
            onChange={handleInputChange}
            value={operatorForm?.Phone}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            E-mail
          </label>
          <input
            type="text"
            id=""
            placeholder="E-mail"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            name="Email"
            onChange={handleInputChange}
            value={operatorForm?.Email}
          />
        </div>
      </div>

      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            CIN
          </label>
          <input
            type="text"
            id=""
            placeholder="CIN"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            name="Cin"
            onChange={handleInputChange}
            value={operatorForm?.Cin}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Code TCP
          </label>
          <input
            type="text"
            id=""
            placeholder="Code TCP (2 charactéres)"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            name="NameCode"
            onChange={handleInputChange}
            value={operatorForm?.NameCode}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Zone
          </label>
          <select
            label="select Version"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            value={operatorForm?.Zone}
            id="op_role"
            name="Zone"
            onChange={handleInputChange}
          >
            <option disabled value="">
              Selectionner votre zone
            </option>
            {listOfZone.map((el) => (
              <option value={el.label}>{el.label} ({el.value})</option>
            ))}
          </select>
        </div>
      </div>

      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Numéro d'immactriculation
          </label>
          <input
            type="text"
            id=""
            placeholder="Numéro d'immactriculation"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            name="UserVehicul"
            onChange={handleInputChange}
            value={operatorForm?.UserVehicul}
          />
        </div>
      </div>

      <div className="mt-5">
        <Button
          variant="gradient"
          color="#236571"
          onClick={() => handleUpdateOp()}
          className="mr-1"
        >
          <span>Modifier</span>
        </Button>
        <Button
          variant="text"
          color="#cf503f"
          onClick={() => handleCancelOp()}
          className="mr-1"
        >
          <span>Annuler</span>
        </Button>
      </div>

      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-2">
        <div>
          <input
            type="password"
            id=""
            placeholder="Mot de passe"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            name="newPassword"
            onChange={handlePasswordInputChange}
            value={operatorPasswordForm?.newPassword}
          />
        </div>
        <div>
          <input
            type="password"
            id=""
            placeholder="Confirmer le mot de passe"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            name="confirmPassword"
            onChange={handlePasswordInputChange}
            value={operatorPasswordForm?.confirmPassword}
          />
        </div>
      </div>

      <div>
        <Button
          variant="gradient"
          color="#236571"
          onClick={() => handleUpdatePassword()}
          className="mt-2"
        >
          <span>Modifier mot de passe de cette utilisateur</span>
        </Button>
      </div>

    </form>
  );
}

export default EditeOp;
